"use client";
import React, { HTMLAttributes, useEffect, useState, type JSX } from "react";
import { AccountingFirmType, CompanyPermissionType, CompanyType, UserType } from "../../../../utilities/redux/types";
import { UserPicture } from "../userPicture/UserPicture";
import { CompanyPicture } from "../companyPicture/CompanyPicture";
import { AccountingFirmPicture } from "../accountingFirmPicture/AccountingFirmPicture";
import { Tooltip } from "../../infos";
import { FaUserAlt, FaUserTie } from "../../../react-icons/fa";
import classNames from "classnames";
import { getCompanyPermission } from "../../../../utilities/tools/converter";
import { AiFillStar } from "../../../react-icons/ai";
import { FaShield } from "../../../react-icons/fa6";

interface Props extends HTMLAttributes<HTMLDivElement> {
  collection: CompanyType[] | UserType[] | AccountingFirmType[];
  app: "expert" | "company" | "admin";
  size?: number;
  type: "user" | "company" | "accountingFirm";
  toolTipLegend?: string | JSX.Element;
  limit?: number;
  companySelected?: CompanyType;
};

const TooltipUser = ({ item, companyPermission }: { item: UserType, companyPermission?: CompanyPermissionType; }) => {

  return (
    <div className="column">
      <strong>{item.firstName + " " + item.lastName}</strong>
      <em>{item.email}</em>

      {item?.roles?.some((item) => item === "ROLE_EXPERT")
        && <div className="row mt-05 a-center fit-content m-auto gap-05">
          {item.roles?.some((item) => item === "ROLE_EXPERT_OWNER")
            ? <><FaUserTie color="var(--color-warning)" /><p>Expert comptable</p></>
            : <><FaUserAlt color="var(--color-main)" /><p>Collaborateur</p></>}
        </div>}

      {companyPermission
        && <div className="row mt-05 a-center fit-content m-auto gap-0">
          {companyPermission?.isAdmin
            ? <>
              <FaShield color="var(--color-main)" />
              <p>Administrateur</p>
            </>
            : (companyPermission?.isOwner
              ? <>
                <AiFillStar color="var(--color-warning)" />
                <p>Dirigeant</p>
              </>
              : <>
                <FaUserAlt color="var(--color-secondary)" />
                <p>Collaborateur</p>
              </>)}

        </div>}
    </div>
  );
};

export default function PictureGroup({ collection, app, size = 30, type = "user", toolTipLegend, limit = null, companySelected, ...props }: Props) {
  const [limitedCollection, setLimitedCollection] = useState<CompanyType[] | UserType[] | AccountingFirmType[]>(collection);
  const [restOfCollection, setRestOfCollection] = useState<CompanyType[] | UserType[] | AccountingFirmType[]>(collection);

  useEffect(() => {
    if (limit) {
      let extracted = collection.slice(0, limit);
      let rest = collection.slice(limit, collection.length + 1);

      /// si il ne reste qu'un élément, pas la peine de slicer, car même tailler avec le +1 dans la div more
      if (rest?.length === 1) {
        setLimitedCollection(collection);
        setRestOfCollection([]);
        return;
      } else {
        /// sinon affecter le reste
        setLimitedCollection(extracted);
        setRestOfCollection(rest);
      }
    } else {
      setLimitedCollection(collection);
      setRestOfCollection([]);
    }
  }, [collection, limit]);

  return (
    <div
      {...props}
      className={classNames("picture-group", props?.className)}
      onClick={(e) => {
        e.stopPropagation;
        props?.onClick && props?.onClick(e);
      }}
    >
      {type == "user" &&
        limitedCollection?.map((item: UserType,) => {
          const companyPermission = getCompanyPermission(item, companySelected);

          return (
            <Tooltip position="bottom" legend={toolTipLegend || <TooltipUser item={item} companyPermission={companyPermission} />} key={`user_${item?.email}`}>
              <UserPicture size={size} email={item.email} user={item} />
            </Tooltip>
          );
        })}

      {type == "company" &&
        limitedCollection?.map((item: CompanyType,) => {
          return (
            <Tooltip position="bottom" legend={toolTipLegend || item.name} key={`company_${item?.slug}`}>
              <CompanyPicture size={size} app={app} isPictureDefined={item.isPictureDefined} slug={item.slug} />
            </Tooltip>
          );
        })}

      {type == "accountingFirm" &&
        limitedCollection?.map((item: AccountingFirmType,) => {
          return (
            <Tooltip position="bottom" legend={toolTipLegend || item.name} key={`accountingFirm_${item?.slug}`}>
              <AccountingFirmPicture size={size} app={app} isPictureDefined={item.isPictureDefined} accountingFirmSlug={item.slug} />
            </Tooltip>
          );
        })}

      {restOfCollection?.length > 0 && (
        <Tooltip
          position="bottom"
          legend={
            <div className="column gap-2">
              {restOfCollection?.map((item,) => {
                return (
                  <TooltipUser item={item} key={`rest_${item.id}`} />
                );
              })}
            </div>
          }
        >
          <div className="more" style={{ width: size - 4 + "px", height: size - 4 + "px", marginLeft: -size * 0.15 + "px" }}>
            +{restOfCollection?.length}
          </div>
        </Tooltip>
      )}
    </div>
  );
}
